/**
 * Basic typography style for copy text
 */

body {
  color: $text-color;
  font-family: $text-font-stack;
  font-size: 20px;
}

footer {
  font-size: 0.8em;
}

h2 {
  font-size: 1.75em;
}