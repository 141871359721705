// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

header {
  position: fixed;
  width: 100%;
  z-index: 20;
  top: 0;
}

/*
* Website logo / title
 */

.site_logo {
  font: 1em $text-font-stack;
  font-weight: bold;
  position: fixed;
  text-transform: uppercase;
  top: 2em;
  left: 1em;
  line-height: 0.95em;
}

/*
* Main menu control
 */

.btn_main_menu {
  cursor: pointer;
  height: 20px;
  width: 25px;
  position: absolute;
  right: 2em;
  top: 2em;

  div {
    height: 2px;
    width: 25px;
    background-color: #000000;
    top: 9px;
    position: absolute;

    &:before, &:after {
      background-color: #000000;
      content: '';
      height: 2px;
      position: absolute;
      width: 100%;
      left: 0;
    }

    &:before {
      top: 6px;
    }

    &:after {
      bottom: 6px;
    }
  }
}