// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      @content;
    }
  }
}


/// Make a context based selector a little more friendly
/// @author Kitty Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}


/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
                    type-of($raw-query) == 'string',
                    unquote($raw-query),
                    inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Fonts loader
/// @access public
/// @param {String} $font-family - Font family name
/// param {String} $font-file - Font file relative path without extension
@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.eot');
    src: url($font-file + '.eot?#iefix') format('embedded-opentype'),
    url($font-file + '.woff') format('woff'),
    url($font-file + '.ttf') format('truetype'),
    url($font-file + '.svg#aller') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}