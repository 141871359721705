// -----------------------------------------------------------------------------
// This file contains all styles related to the main content's view of the site/application.
// -----------------------------------------------------------------------------

.layer {
  background-color: #C50;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  display: none;
  opacity: 0.8;
}

.layer--is-enabled {
  display: block;
  animation: layer-is-enabling 0.5s ease 0s 1 normal forwards;
}

.legal-notice {
  position: fixed;
  overflow: hidden;
  color: #F4661B;
  display: none;
  z-index: 100;
  background-color: #FFFFFF;
  top: 1%;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  bottom: 0;
}

.legal-notice--is-open {
  display: block;
  animation: popin-is-opening 1s ease 0s 1 normal forwards;
}

.legal-notice__title {
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}

@media only screen and (max-width: 927px) {
  .legal-notice {
    width: 100%;
    top: 0;
  }

  .layer {
    opacity: 1;
  }
}