// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'proxima_regular' !default;

/// App title font family
/// @type List
$app-title-bold-font-stack: 'proxima_bold' !default;

/// Text bold font family
/// @type List
$text-bold-font-stack: 'optima' !default;


/// Copy text color
/// @type Color
$text-color: lighten(rgb(19, 18, 16), 0%) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(5, 0, 0) !default;

/// Container's maximum width
/// @type Length
$max-width: 100% !default;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
        'extra-small': (max-width 600px),
        'small': (min-width 600px),
        'medium': (min-width: 800px),
        'large': (min-width: 1000px),
        'extra-large': (min-width: 1200px)
);


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'https://cdn.example.com/assets/';
$base-url: '../' !default;


/// bezier curve for animation
/// @type transition-timing-function
/// @link https://developer.mozilla.org/fr/docs/Web/CSS/easing-function
/// @link https://drafts.csswg.org/css-transitions/#transition-timing-function-property

/// Expo
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);