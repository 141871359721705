@keyframes popin-is-opening {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes layer-is-enabling {
  0% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1);
  }
}