body.home.home--no-scrollable {
  overflow: hidden;
}

body.home {
  $color1: #ffdd9f;
  $color2: #f8d8ac;
  $color3: #f8e4cb;

  .newsletter-subscription {
    position: fixed;
    overflow: hidden;
    color: #F4661B;
    z-index: 100;
    background-color: #FFFFFF;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    bottom: 25%;

    input {
      display: block;
      color: #F4661B;
      font-weight: bold;
      margin: 10px auto;
      border-width: 0 1px 1px;
      border-color: #AD4F09;
      border-style: dotted;
      width: 47%;

      &[type=submit] {
        background-color: #F4661B;
        border: none;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        color: #ffffff;
        width: auto;
      }
    }
  }

  .newsletter-subscription--is-enabled {
    display: block;
    animation: popin-is-opening 1s ease 0s 1 normal forwards;
  }

  .newsletter-subscription__close-button {
    width: 2em;
    border-radius: 48%;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 1%;
    left: 1%;
    box-shadow: -7px -9px 1px 15px rgba(223, 109, 20, 0.9);
    -webkit-box-shadow: -7px -9px 1px 15px rgba(223, 109, 20, 0.9);
    -moz-box-shadow: -7px 1px -9px 15px rgba(223, 109, 20, 0.9);
  }

  .newsletter-subscription__title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
  }

  .newsletter-subscription__error-message {
    text-align: center;
    display: none;
  }

  .newsletter-subscription__error-message--is-enabled {
    display: block;
  }

  .newsletter-subscription__form__subscription-confirmation {
    width: 47%;
    margin: 0 auto;

    input {
      margin: 0 10px 0 0;
      display: inline-block;
      width: auto !important;
    }

    label {
      cursor: pointer;
      font-size: 0.9em;

      button {
        background: none;
        border: none;
        text-decoration: underline;
        color: #A75502;
      }
    }
  }

  @supports (-webkit-font-smoothing: antialiased) {
    .newsletter-subscription__form__subscription-confirmation {
      width: 48%;
    }
  }

  .newsletter-subscription__error-message--is-enabled {
    animation: highlight 1.5s ease-out;
  }

  @keyframes highlight {
    0% {
      background-color: #C50;
      color: #ffffff;
    }
    100% {
      background-color: #ffffff;
      color: inherit;
    }
  }

  .newsletter-layer {
    background-color: #F3D617;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    opacity: 0.8;
    display: none;
    top: 0;
    left: 0;
  }

  .newsletter-layer--is-enabled {
    display: block;
  }

  .newsletter-spinner {
    width: 10%;
    animation: rotating 2s linear infinite;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 100;
    left: 45%;
    top: 30%;
    display: none;
  }

  .newsletter-spinner--is-enabled {
    display: block;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .home__menu {
    position: fixed;
    z-index: 1;
    background: rgb(204, 85, 0);
    width: 50px;
    height: 100%;
    text-align: center;
  }

  .home__menu img {
    width: 1.25em;
    cursor: pointer;
  }

  .home__menu__coordinates img {
    width: 1.4em;
  }

  .home__menu__typologies img {
    width: 1.6em;
  }

  .home__menu__skills img {
    width: 1.6em;
  }

  .home__menu__asso-projects img {
    width: 1.6em;
  }

  .home__menu__freelance-xp img {
    width: 1.8em;
  }

  .home__menu__employees-xp img {
    width: 1.4em;
  }

  .home__menu__employees-xp img {
    width: 1.4em;
  }

  .homme__menu__perso-activities img {
    width: 1.8em;
  }

  .home__menu__newsletter img {
    width: 1.9em;
  }

  .home-menu-button {
    position: fixed;
    top: 1em;
    left: 16px;
    height: 15px;
    width: 23px;
    z-index: 16;
    cursor: pointer;
    display: none;

    span {
      height: 2px;
      width: 100%;
      background: #000;
      position: absolute;
      top: 6px;
      left: 0;
      opacity: 1;
      transition: 0.65s $easeOutExpo;
    }

    &:before {
      content: '';
      width: 23px;
      height: 2px;
      top: -1px;
      left: 0;
      position: absolute;
      background: #000;
      transition: 0.65s $easeOutExpo;
    }

    &:after {
      content: '';
      width: 23px;
      height: 2px;
      bottom: 0;
      left: 0;
      position: absolute;
      background: #000;
      transition: 0.65s $easeOutExpo;
    }
  }

  .home-menu-button.home-menu-button--is-open {
    span {
      left: -50px;
      opacity: 0;
      transition: 0.65s $easeOutExpo;
    }

    &:before {
      left: 5px;
      top: -1px;
      width: 100%;
      transform-origin: top left;
      transform: rotate(45deg);
      transition: 0.65s $easeOutExpo;
    }

    &:after {
      left: 5px;
      bottom: -2px;
      width: 100%;
      transform-origin: bottom left;
      transform: rotate(-45deg);
      transition: 0.65s $easeOutExpo;
    }
  }

  h2 {
    text-align: center;
  }

  .coordinates {
    background-color: $color1;
  }

  .coordinates > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px;
    width: 60%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
  }

  .coordinates__additionals-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      width: 30px;
    }
  }

  .my-photo {
    position: relative;

    img {
      max-width: 60%;
      height: auto;
      padding: 20px 0;
    }
  }

  .titles {
    text-align: center;
    margin: 20px 0;
  }

  .titles strong {
    display: block;
    margin: 15px 0 0;
    font-size: 1.5em;
  }

  .job-typologies {
    background-color: $color2;
    padding: 5px 0;
  }

  .job-typologies > div {
    width: 53%;
    margin: 0 auto;
  }

  .job-typologies ul {
    padding: 10px;
    width: 60%;
    margin: 0 auto;
  }

  .job-typologies li {
    margin: 0 0 20px;
    list-style-type: disclosure-closed;
  }

  .technicals_skills__grid {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    text-align: center;
  }

  .technicals_skills__grid ul {
    padding: 0;
  }

  .technicals_skills__grid ul li {
    list-style-type: none;
  }

  .langs {
    margin: 0 0 45px;
  }

  .langs p {
    text-align: center;
  }

  .experiences {
    background-color: $color3;
    padding: 10px;
  }

  .experiences .experiences__typeof {
    width: 53%;
    margin: 0 auto 55px;
  }

  .experiences ul {
    border-style: solid;
    border-width: 0 0 0 5px;
    border-color: #C50;
    list-style: none;
  }

  .experiences li {
    margin: 0 0 20px;
    list-style-type: none;
  }

  .experiences__experience {
    position: relative;
    margin: 0 0 60px;
  }

  .experiences__experience--is-working-progress {
    border-width: 1px 1px 0 0;
    border-style: solid;
    border-image: linear-gradient(45deg, $color3 60%, #E73E01) 1;
  }

  .experiences__experience--is-working-progress::before {
    content: "en cours";
    color: #E73E01;
    position: absolute;
    right: 5px;
  }

  .experiences__experience_tags {
    border-style: solid;
    border-width: 1px 0;
    padding: 5px 0;
  }

  .experiences__typeof__employee h2 {
    margin: 0 0 55px;
  }

  .trainings, .perso-activities {
    width: fit-content;
    margin: 0 auto;
    padding: 0 10px;
  }

  .perso-activities {
    width: 35%;
  }

  .timeline {
    position: relative;
    padding: 0 20px 40px;
  }

  .timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #000;
    top: 0;
    bottom: 0;
    left: 199px;
  }

  .timeline__item {
    position: relative;
    margin-bottom: 50px;
  }

  .timeline__item::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #000;
    border-radius: 50%;
    top: 0;
    left: 170px;
  }

  .timeline__item__content {
    position: relative;
    padding-left: 233px;
  }

  .timeline__item__content__timeline-date {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .timeline__item__content__timeline-description {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 927px) {
  body.home {
    .newsletter-subscription {
      display: flex;
      top: 0;
      left: 0;
      transform: none;

      input {
        width: 76%;
      }
    }

    .newsletter-subscription-response {
      display: block;
      text-align: center;
      width: 100%;
    }

    .newsletter-subscription__close-button {
      box-shadow: -7px -5px 0px 12px rgba(223, 109, 20, 0.9);
      -webkit-box-shadow: -7px -5px 0px 12px rgba(223, 109, 20, 0.9);
      -moz-box-shadow: -7px -5px 0px 12px rgba(223, 109, 20, 0.9);
    }

    .newsletter-subscription__form__subscription-confirmation {
      width: 76%;
    }

    ::selection {
      color: #FFCB60;

      background-color: #E73E01;

    }

    .home__menu {
      display: none;
      gap: 25px;
      justify-content: center;
      position: fixed;
      width: 100%;
      height: auto;
      z-index: 1;
      align-items: center;
      background: rgba(204, 85, 0, .5);
    }

    .home__menu.home__menu--is-open {
      display: flex;
    }

    .home-menu-button {
      display: block;
    }

    .coordinates > div {
      width: 100%;
    }

    .coordinates__linkedin-ico {
      width: 7%;
    }

    .job-typologies > div {
      width: 100%;
    }

    .experiences > .experiences__typeof {
      width: 100%;
    }

    .perso-activities {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 430px) {
  body.home {
    .newsletter-subscription {
      display: block;

      input {
        width: 95%;
      }
    }

    .newsletter-subscription__form__subscription-confirmation {
      width: 95%;
    }

    @supports (-webkit-font-smoothing: antialiased) {
      .newsletter-subscription__form__subscription-confirmation {
        width: 98%;
      }
    }

    .newsletter-subscription__close-button {
      top: 0.5%;
      left: 2%;
    }

    .home-menu-button {
      top: 1%;
      left: 3%;
    }

    .home__menu {
      padding-top: 10%;
    }

    .home__menu.home__menu--is-open {
      display: block;
    }

    .home__menu {
      width: 50px;
      height: 100%;
      text-align: center;
    }

    .coordinates > div {
      grid-template-columns: 1fr;
    }

    .coordinates__linkedin-ico {
      width: 10%;
    }

    .job-typologies ul {
      width: 90%;
    }

    .perso-activities {
      width: 100%;
    }

    .timeline::after {
      left: 45px;
    }

    .timeline__item::after {
      left: 16px;
    }

    .timeline__item__content {
      padding-left: 92px;
    }
  }
}